import {createStore} from 'vuex'

var store = createStore({
    state: {
        token: localStorage.getItem('token') || '',
        user: null,
        balance: 0,
        fund:0,
        isFull:false,  //是否全屏
        mainBgColor:'url(/img/bg.png)', //默认背景色
        balanceWarning:false,  // 金豆不足提醒
        servicePhone:'18637170946',
        roomStateVersion:1,//房态版本
        mode: '0'
    },
    getters: {
        getRoomStateVersion(state){
            return state.roomStateVersion
        },
        getServicePhone(state){
            return state.servicePhone || '18637170946'
        },
        getUser(state) {
            if (state.user) {
                return state.user
            } else {
                let result = localStorage.getItem('user')
                if (result) {
                    result = JSON.parse(result)
                    return result
                }
                return null
            }

        },
        // 获取是否全屏配置
        getIsFull(state){
            return state.isFull;
        },
        // 获取背景色配置
        getMainBgColor(state){
            return state.mainBgColor;
        },
        // 获取金豆不足状态
        getMainBgColor(state){
            return state.balanceWarning;
        }
    },
    mutations: {
        setRoomStateVersion(state,v){
            console.log('-----setRoomStateVersion-------',v)
            state.roomStateVersion = v
            localStorage.setItem('roomStateVersion', v);
        },
        setServicePhone(state, phone){
            state.servicePhone = phone
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        setMode(state, mode) {
            state.mode = mode;
            localStorage.setItem('mode', mode);
        },
        setUserInfo(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        // 金豆
        setBalance(state, balance) {
            state.balance = balance;
            console.log(state,"改变");
        },
        setFund(state, fund) {
            state.fund = fund;
             console.log(fund)
             console.log('1235255')
            console.log(state,"改变");
        },
        setIsFull(state, isFull) {
            state.isFull = isFull;
        },
        setMainBgColor(state,mainBgColor){
            state.mainBgColor = mainBgColor;
        },
        setBalanceWarning(state,balanceWarning){
            state.balanceWarning = balanceWarning;
        },
        checkBalance(state) {
            var balance = state.balance;
            // 小于等于20  提醒充值，但是不影响登记
			if (balance <= 20 && balance > 0) {
				this.commit("setBalanceWarning", true);
				return false;
			} else if (balance <= 0) {
				// 小于等于0，强制充值，不能登记
				this.commit("setBalanceWarning", true);
				return true;
			}
			// 其余不显示充值提醒
			return false; 
        }
    },
    actions: {
        setRoomStateVersion({commit},v){
            commit('setRoomStateVersion', v);
        },
        setToken({commit}, token) {
            commit('setToken', token);
        },
        setMode({commit}, token) {
            commit('setMode', token);
        },
        setUserInfo({commit}, setUserInfo) {
            commit('setUserInfo', setUserInfo);
        },
        setBalance({commit}, balance) {
            console.log(balance,"2222");
            commit('setBalance', balance);
        },
        setFund({commit}, fund) {

            commit('setFund', fund);
        },
        
        setIsFull({commit},isFull){
            commit('setIsFull', isFull);
        },
        setMainBgColor({commit},mainBgColor){
            commit('setMainBgColor', mainBgColor);
        },
        setBalanceWarning({commit},balanceWarning){
            commit('setBalanceWarning', balanceWarning);
        }

        
    },
    modules: {}
})

export default store
