<template>
  <router-view />
</template>

<script>
import { useStore } from 'vuex'

import store from '@/store/index'
export default {
  name: 'App2',
  setup() {
    const store = useStore()

    const isMode = localStorage.getItem('mode')
    if (isMode) {
      store.commit('setMode', isMode)
    }
  }
}

</script>
<style lang="scss">
// @import url("./001.scss");

@import url("../public/common.css"); 
.fade-enter-from,
.fade-leave-to {
  /*定义进入开始和离开结束的透明度为0*/
  opacity: 0.3;
  transform: translateX(-30px);
}

.fade-enter-to,
.fade-leave-from {
  /*定义进入结束和离开开始的透明度为1*/
  opacity: 1;
  transform: translateX(0px);
}
.fade-leave-active,.fade-enter-active {
  transition: all 0.4s ease-out;
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: PingFangSC-Medium, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
