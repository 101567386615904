import { createRouter, createWebHashHistory } from 'vue-router'



const routes = [
  {
    path:"/",

    redirect:"login"
  },
  {
    path: '/userreg',
    name: 'userreg',
    component: () => import(/* webpackChunkName: "about" */ '../views/userreg/index.vue')
  },
  {
    path:"/login",
    name:"login",
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/akbfajfjsna21',
    name: 'success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/success.vue')
  },
  {
    path: '/lkajd',
    name: 'forget',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/forget.vue')
  },

  {
    path:"/layout",
    name:"layout",
    children:[
      {
        path:"index",
        name:"index",
        component: () => import(/* webpackChunkName: "chunkA" */ '../views/index.vue')
      },
      {
        path:"list",
        name:"list",
        component: () => import(/* webpackChunkName: "chunkB" */ '../views/list/index.vue')
      },
      {
        path:"reg",
        name:"reg",
        component: () => import(/* webpackChunkName: "chunkC" */ '../views/reg/index.vue')
      },
      {
        path:"hose",
        name:"hose",
        redirect:"/layout/hosev2",
      },
      {
        path:"hosev2",
        name:"hosev2",
        component: () => import(/* webpackChunkName: "chunkD" */ '../views/check-in/index.vue')
      },
      {
        path:"setting",
        name:"setting",
        component: () => import(/* webpackChunkName: "chunkE" */ '../views/setting/index.vue')
      },
      {
        path:"ticket",
        name:"ticket",
        component: () => import(/* webpackChunkName: "chunkG" */ '../views/ticket/index.vue')
      },
      {
        path:"lock",
        name:"lock",
        component: () => import(/* webpackChunkName: "chunkH" */ '../views/lock/index.vue')
      },
      {
        path:"test",
        name:"test",
        component: () => import(/* webpackChunkName: "chunkI" */ '../views/test/index.vue')
      },
      {
        path:"bill",
        name:"bill",
        component: () => import(/* webpackChunkName: "chunkJ" */ '../views/bill/index.vue')
      },
      {
        path:"userreg",
        name:"userreg2",
        component: () => import(/* webpackChunkName: "aboutK" */ '../views/userreg/index.vue')
      },{
        path:"reguser",
        name:"reguser",
        component: () => import(/* webpackChunkName: "aboutL" */ '../views/reguser/index.vue')
      },{
        path:"record",
        name:"record",
        component: () => import(/* webpackChunkName: "aboutL" */ '../views/distribute_record/index.vue')
      },{
        path:"displace",
        name:"displace",
        component: () => import(/* webpackChunkName: "aboutL" */ '../views/displace/index.vue')
      }


    ],
    component: () => import(/* webpackChunkName: "aboutN" */ '../views/layout/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token');  // 假设您的token存储在localStorage中

//   if (!token && to.name !== 'login') {
//     next({ name: 'login' });  // 跳转到登录页
//   } else {
//     next();  // 继续导航
//   }
// });
export default router
