import { createApp } from 'vue'
import '../public/element.scss'
import "./001.scss"
import App from './App.vue'
import router from './router'
import store from './store'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '../public/iconfont.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { ElMessage } from 'element-plus'
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css


//console.log(ElementPlus,"pl");
var  app  = createApp(App)
app.config.globalProperties.$msg = ElMessage;
app.use(vue3videoPlay);
app.use(ElementPlus,{locale:zhCn}).use(store).use(router).mount('#app')
// main.js
console.log('build time:', process.env.APP_VERSION)